import { mergeDeep } from "../../services/utils";
// core
import en_default from "../core/l10n/en/en";
// custom
import custom_en_default from "./l10n/en/en";

const resources = {
	en: {
		default: mergeDeep(en_default, custom_en_default),
	},
} as const;

export default resources;
