module.exports = {
	jsHeadInjects: [],
	showCookieMsg: false,
	title: "ROC Home",
	supportedCountries: ["AT"], // don't sort alphabetically
	defaultCountry: "AT",
	supportedCurrencies: ["EUR"], // don't sort alphabetically
	defaultCurrency: "EUR",
	defaultBbox: [[46.470195, 14.532982], [48.736822, 16.843372]],
	showExplore: false,
	serverOptions: [
		{
			label: "DEV",
			id: "dev",
			channel: "roc",
			docsVersion: "develop",
			accessCookieConfig: {
				name: "glient2idroc",
				path: "/glient2/roc",
				domain: ".prod.faulpelz.io",
			},
			refreshCookieConfig: {
				name: "refreshroc",
				path: "/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/roc",
				domain: ".prod.faulpelz.io",
			},
			glientWsUrl: "wss://api-client.prod.faulpelz.io/glient2/roc",
			redirectUrl: "https://prod.faulpelz.io/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/roc/redirect",
			revokeUrl: "https://prod.faulpelz.io/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/roc/logout",
			invalidateTokenUrl: "https://prod.faulpelz.io/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/roc",
			editAccountUrl: "https://prod.faulpelz.io/api/profile/c0b6b885586709feafbaf25801bc93a2/redirect",
			// migrationWizardUrl: "",
			geofenceEventUrl: "https://api-phone.prod.faulpelz.io/api/device/roc/phone/",
			widgetSetModeUrl: "https://api-client.prod.faulpelz.io/api/public/roc/glient/",
		},
		{
			label: "ALPHA",
			id: "alpha",
			channel: "roc",
			docsVersion: "develop",
			accessCookieConfig: {
				name: "glient2idroc",
				path: "/glient2/roc",
				domain: ".dev.faulpelz.io",
			},
			refreshCookieConfig: {
				name: "refreshroc",
				path: "/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/roc",
				domain: ".dev.faulpelz.io",
			},
			glientWsUrl: "wss://api-client.dev.faulpelz.io/glient2/roc",
			redirectUrl: "https://dev.faulpelz.io/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/roc/redirect",
			revokeUrl: "https://dev.faulpelz.io/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/roc/logout",
			invalidateTokenUrl: "https://dev.faulpelz.io/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/roc",
			editAccountUrl: "https://dev.faulpelz.io/api/profile/c0b6b885586709feafbaf25801bc93a2/redirect",
			// migrationWizardUrl: "",
			geofenceEventUrl: "https://api-phone.dev.faulpelz.io/api/device/roc/phone/",
			widgetSetModeUrl: "https://api-client.dev.faulpelz.io/api/public/roc/glient/",
		},
		{
			label: "ROC",
			id: "prod",
			channel: "roc",
			docsVersion: "public",
			accessCookieConfig: {
				name: "glient2idroc",
				path: "/glient2/roc",
				domain: ".prod.faulpelz.io",
			},
			refreshCookieConfig: {
				name: "refreshroc",
				path: "/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/roc",
				domain: ".prod.faulpelz.io",
			},
			glientWsUrl: "wss://api-client.prod.faulpelz.io/glient2/roc",
			redirectUrl: "https://prod.faulpelz.io/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/roc/redirect",
			revokeUrl: "https://prod.faulpelz.io/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/roc/logout",
			invalidateTokenUrl: "https://prod.faulpelz.io/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/roc",
			editAccountUrl: "https://prod.faulpelz.io/api/profile/c0b6b885586709feafbaf25801bc93a2/redirect",
			// migrationWizardUrl: "",
			geofenceEventUrl: "https://api-phone.prod.faulpelz.io/api/device/roc/phone/",
			widgetSetModeUrl: "https://api-client.prod.faulpelz.io/api/public/roc/glient/",
		},
	],
	devAppId: {
		android: "com.roc_connect.rocapp.master",
		ios: "com.roc-connect.app.master",
	},
	// storeId: {
	// 	android: "com.roc_connect.rocapp.master",
	// 	ios: 0, // TODO
	// },
};
